export default {
  env: 'stage',
  stripe_public_key: 'pk_test_QeKQj2ThQ4mqatyLsXCexOmB00he3FXx2P',
  lms_api_url: 'https://dev.harperdbcloudservices.com/',
  google_analytics_code: 'G-PP7CR3ELDX',
  youtube_api_key: 'AIzaSyDcAYCF6FFfEvZ45lzyizdQOeb5-daqLWc',
  postman_collection_url: 'https://www.postman.com/collections/7046690-5a70b340-8fe1-4487-88bd-ffac077c3df8',
  tc_version: '2020-01-01',
  check_version_interval: 300000,
  refresh_content_interval: 15000,
  total_cloud_instance_limit: 10,
  free_cloud_instance_limit: 1,
  total_local_instance_limit: false,
  free_local_instance_limit: false,
  max_file_upload_size: 10380902,
  studio_version:'4.3.5',
  user_guide_id: 16032,
  alarm_badge_threshold: 86400,
  maintenance: 0,
  errortest: 0,
  is_local_studio: false,
};
